@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $black;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	width: 100%;
	overflow-x: hidden;
	position: relative;
}

a {
	color: inherit;

	@include hover-focus {
		color: $white;
	}
}

.btn {
	font-size: rems(12);
	border: 1px solid $white;
	padding: rems(13) rems(57);

	@include hover-focus {
		background-color: $white;
		color: $black;
	}
}

iframe {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: calc((var(--iframeHeight) / var(--iframeWidth)) * 100%);
	// padding-bottom: calc(iframe height / iframe width * 100%);
	overflow: hidden;
	margin: 0 auto;
}

iframe::before {
	content: "";
	display: block;
	padding-top: calc((var(--iframeWidth) / var(--iframeHeight)) * 100%);
	// padding-top: calc(iframe width / iframe height * 100%);
}

header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background-color: transparent;
	z-index: 100;
	transition: all .3s ease-in-out;

	&.active {
		background-color: #000;
		padding-bottom: rems(50);
	}

	nav.container {
		display: flex;
		align-items: center;
		color: $white;

		@include tablet-down {
			padding: 0;
			flex-direction: column;
			align-items: center;
		}


		h1 {
			text-transform: uppercase;
			font-size: rems(64);
			font-weight: 700;
			text-wrap: nowrap;
			display: inline-block;
			margin: 0 rems(24) 0 0;
			padding: rems(24) 0 rems(8);
			transition: all .3s ease-in-out;

			@include hover-focus {
				color: $black;
			}

			@include tablet-down {
				margin: 0 rems(24);

			}
		}

		ul.home-nav {
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 rems(38) 0 auto;
			padding: 0;
			position: relative;
			gap: rems(24);

			@include tablet-down {
				margin: 0 auto rems(20);
			}

			li {
				font-size: rems(28);
				font-weight: 700;
				text-transform: uppercase;
				margin-top: rems(8);

				a {
					@include hover-focus {
						color: $black;
					}

					img {
						max-width: rems(300);

						@include hover-focus {
							transform: scale(1.1);
						}
					}
				}
			}
		}

		a.btn {
			text-transform: uppercase;
		}

		ul.small-nav {
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: rems(20);
			margin: rems(50) 0 0 auto;
			padding: 0;
			transition: all .3s ease-in-out;
			max-width: rems(800);
			// transform: scale(.7);

			// @include hover-focus {
			// 	transform: scale(1);
			// }

			@media screen and (max-width: 1200px) {
				width: 100%;
			}

			@include tablet-down {
				width: 0;
				opacity: 0;
				pointer-events: none;
				flex-direction: column;
				margin-top: rems(40);

				&.active {
					transition: all .3s ease-in-out;
					opacity: 1;
					pointer-events: all;
					width: 100%;
				}
			}

			.active-page {
				opacity: .6;
				z-index: 5;
			}

			li {
				position: relative;
				transition: all .3s ease-in-out;

				@include hover-focus {
					transform: scale(1.1);
				}

				a {
					display: block;
					height: 100%;
				}

				img {
					max-width: 100%;
					max-height: 100%;
					width: rems(191);
					height: rems(107);
					object-fit: cover;
					overflow: hidden;
				}

				p {
					font-size: rems(16);
					font-weight: 700;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 6;
					text-transform: uppercase;

					@media screen and (max-width: 1200px) {
						font-size: rems(18);
						text-align: center;
					}
				}
			}
		}
	}

	.mobile-menu-icon {
		display: none;
		position: absolute;
		right: rems(20);
		top: rems(24);
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 35px;
		height: 45px;
		pointer-events: all;
		cursor: pointer;
		z-index: 30;

		@include tablet-down {
			// margin-bottom: rems(32);
			display: flex;
		}
	}

	.line {
		width: 100%;
		height: 4px;
		background-color: $white;
		margin: 4.5px;
		transition: transform 0.3s ease;
		transform-origin: center;


		&.active {
			transform: rotate(45deg) !important;
			position: absolute;
			top: 30%;

			&.active:nth-child(2) {
				display: none;
			}

			&.active:nth-child(3) {
				transform: rotate(-45deg) !important;
			}
		}
	}
}

.home header {

	.mobile-menu-icon {
		opacity: 0;
		pointer-events: none;
		width: 0;
	}

	.lines {}

}

main {

	section {
		min-height: 100vh;
	}

	#tour,
	#music {
		position: relative;

		.bg-img {
			object-fit: cover;
			position: fixed;
			top: 0;
			bottom: 0;
			left: -25%;
			height: 100%;
			width: 100%;

			@include tablet-down {
				left: 0;
				right: 0;
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: rems(190) rems(16) rems(200);
			min-height: 100vh;
		}
	}

	#home {
		// background-image: url(../img/bg/hero.jpeg);
		// background-size: cover;
		// background-position: center;
		width: 100%;
		min-height: 100vh;
		position: relative;

		@include tablet-down {
			background-position: 25%;
		}

		video#background-video {
			position: absolute;
			top: 0;
			bottom: 0;
			object-fit: cover;
			object-position: center;
			z-index: -1;
			min-height: 100vh;

			@media screen and (max-width: 700px) {
				display: none;
			}
		}

		img#mobile-bg {
			position: absolute;
			top: 0;
			bottom: 0;
			object-fit: cover;
			object-position: center;
			z-index: -1;
			min-height: 100vh;

			@media screen and (min-width: 701px) {
				display: none;
			}
		}


		nav {
			padding: 45vh rems(24) 0;
			width: 100%;

			@media screen and (max-width: 700px) {
				padding: 70vh rems(24) 0
			}

			ul.nav {
				list-style: none;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: rems(20);
				margin: 0 auto;
				padding: 0;
				transition: all .4s ease-in-out;
				transform: scale(.7);

				@include tablet-down {
					transform: scale(1)
				}

				li {
					padding: 0;
					margin: 0;
					width: rems(275);
					height: rems(152);
					position: relative;

					.img-wrapper {
						position: relative;
						overflow: hidden;
						width: 100%;
						height: 100%;

						img {
							position: relative;
							width: 100%;
							height: 100%;
							object-fit: cover;
							z-index: 5;
						}
					}

					p {
						position: absolute;
						top: 53%;
						left: 0;
						right: 0;
						z-index: 3;
						text-align: center;
						text-transform: uppercase;
						font-weight: 700;
						font-size: rems(16);
						transition: all .4s ease-in-out;
					}
				}

				@media screen and (min-width: 501px) {
					@include hover-focus {
						transform: scale(1);

						li {
							p {
								transform: translateY(rems(100));
							}
						}
					}
				}
			}
		}

		.spotify-embed {
			max-width: rems(400);
			margin: rems(80) auto 0;

			@media screen and (max-width: 700px) {
				margin: rems(30) auto 0;
			}
		}
	}

	#tour {

		.content {
			margin-left: auto;
			width: 55vw;
			background-color: #00000088;
			backdrop-filter: blur(6px);

			@include tablet-down {
				width: 100vw;
			}

			#toggle-dates {
				display: block;
				margin: rems(40) 0;
			}

			.overflow-wrapper {
				max-width: rems(500);
				width: 100%;

				#tour-dates {
					padding-right: rems(16);

					.no-events {
						font-size: rems(22);
						font-weight: rems(700);
					}

					.event-group-wrapper:nth-child(n+9) {
						display: none;
					}

					.event-group {
						display: flex;
						gap: rems(16);
						line-height: 1.1em;
						font-size: rems(12);
						margin-bottom: rems(16);
						justify-content: space-between;

						.event-date {
							flex: .7;
							font-weight: 700;
							text-transform: capitalize;
						}

						.event-location-group {
							flex: 1.8;
							text-transform: capitalize;

							.event-location {}

							.event-venue {}
						}



						.event-links {
							flex: 2;
							text-align: right;
							display: flex;
							align-items: center;
							justify-content: flex-end;

							a {
								text-transform: capitalize;
							}
						}
					}
				}
			}
		}
	}

	#music {

		.content {
			margin-left: auto;
			width: 55vw;
			background: rgba(0, 0, 0, 0.6);
			backdrop-filter: blur(10px);

			@include tablet-down {
				width: 100%;
			}

			.overflow-wrapper {
				max-width: rems(450);
				width: 100%;


				.cover-wrapper {
					padding-right: rems(16);
					text-align: center;

					@include tablet-down {
						padding: 0;
					}

					img.cover {
						max-width: rems(544);
						width: 100%;
						max-height: rems(544);
						height: 100%;
					}

					a {
						display: inline-block;
						margin: rems(20) 0 rems(80);
					}
				}
			}
		}
	}

}


footer {
	position: absolute;
	left: 0;
	right: 0;
	bottom: rems(16);
	z-index: 10;
	display: flex;

	@media screen and (max-width: 1100px) {
		flex-direction: column-reverse;
		align-items: center;
		// text-align: center;
		// position: static;
		padding-top: rems(20);
	}

	.copy-wrapper {
		margin: 0;
		padding: 0 rems(50) rems(8);
		width: 100%;

		@media screen and (max-width: 1100px) {
			padding: 0 rems(5) 0;
		}

		.copyright {

			@media screen and (max-width: 1100px) {
				text-align: center;
			}

			.rca {
				max-width: rems(65);
				padding-bottom: rems(10);

				@media screen and (max-width: 1100px) {
					margin: 0 auto;
				}
			}

			p {
				font-size: rems(8);
				width: 100%;

				a {}
			}
		}
	}

	ul.social {
		display: flex;
		align-items: center;
		list-style: none;
		gap: rems(16);
		padding-right: rems(40);

		li {
			a {
				display: block;
				max-width: rems(20);
				transition: all .3s ease-in-out;

				@include hover-focus {
					transform: scale(1.2);
				}

				@media screen and (max-width: 1100px) {}

				img {}
			}
		}
	}
}

.no-scroll {
	overflow: hidden;
	text-align: center;
}